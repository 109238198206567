import React, { Fragment, useState, useRef, createContext } from 'react';
import { Popover, Transition } from '@headlessui/react'
import Compact from '@uiw/react-color-compact';
import html2canvas from 'html2canvas';
import {
  CloudArrowDownIcon,
  AdjustmentsHorizontalIcon,
  CheckIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import PosterCard from '../components/card_poster';
import { usePageContext, useStatsContext } from "../AppContext";


export const VerseContext = createContext();

function CardPage({ post }) {
  const componentRef = useRef();
  const { setPage } = usePageContext();
  const { updateDownloadCount/*, updateViewCount*/ } = useStatsContext();

  //const [init_load, setInitLoad] = useState(true);
  const [hex_bg, setHexBg] = useState(post.bg_color);
  const [hex_txt, setHexTxt] = useState(post.txt_color);
  const [loading] = useState(false);

  const [is_editable, setIsEditable] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [original_hex_bg, setOriginalHexBg] = useState(hex_bg);
  const [original_hex_txt, setOriginalHexTxt] = useState(hex_txt);


  const revertChanges = (revert) => {
    setCancel(revert);
    if (revert === true) {
      setHexBg(original_hex_bg);
      setHexTxt(original_hex_txt);
    } else {
      setOriginalHexBg(hex_bg);
      setOriginalHexTxt(hex_txt);
    }
  }


  const saveCardAsPNG = (domElement) => {
    html2canvas(domElement, { backgroundColor: null }).then((canvas) => { // Set background color to null for transparency
      return canvas.toDataURL('image/png', 1.0)
    }).then((image) => {
      saveAs(image, 'sm-card.png');

      // Increment downloadCount
      post = updateDownloadCount(post);
    });
  };

  const saveAs = (blob, fileName) => {
    const elem = window.document.createElement('a');
    elem.href = blob
    elem.download = fileName;
    (document.body || document.documentElement).appendChild(elem);
    if (typeof elem.click === 'function') {
      elem.click();
    } else {
      elem.target = '_blank';
      elem.dispatchEvent(new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      }));
    }
    URL.revokeObjectURL(elem.href);
    elem.remove()
  }

  /*useEffect(() => {

    if (init_load) {
      // Increment view count
      setInitLoad(false);
      updateViewCount();
    }

  }, [updateViewCount, init_load]);*/


  return (
    <div className="flex flex-col max-w-sm items-center">

      {/* Customize, Download */}
      {!is_editable && <div className="">
        <ul className="flex flex-row mt-2">
          <li className='mr-4'>
            <button className="text-orange-600 hover:text-orange-900 dark:text-orange-400 dark:hover:text-orange-200 rounded-lg inline-flex"
              onClick={() => setIsEditable(!is_editable)}>
              <AdjustmentsHorizontalIcon className='w-6 h-6 pr-1' />
              <span>Customize</span>
            </button>
          </li>
          <li className=''><span className="border-r border-orange-600 dark:border-orange-400 h-4 inline-block"></span></li>
          <li className='ml-4'>
            <button className=" text-orange-600 hover:text-orange-900 dark:text-orange-400 dark:hover:text-orange-200 rounded-lg inline-flex"
              onClick={() => saveCardAsPNG(componentRef.current)}>
              <CloudArrowDownIcon className='w-6 h-6 pr-1' />
              <span>Download</span>
            </button>
          </li>
        </ul>
      </div>}

      {/* Edit Colors */}
      {is_editable && <div className="">
        <ul className="flex flex-row">
          <li className='mr-4 text-slate-500'>
            <div>
              <Popover className="relative">
                <>
                  <Popover.Button className='text-opacity-90 group inline-flex rounded-md bg-slate-500 px-3 py-2 text-sm font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
                    <svg
                      className="fill-current w-5 h-5 pr-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32 32"
                      xmlSpace="preserve">
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <g>
                          <path d="M29,27H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h26c0.6,0,1-0.4,1-1S29.6,27,29,27z"></path>
                          <path d="M6.4,16.7C6.4,16.7,6.4,16.7,6.4,16.7l7,7c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3l6.9-6.9c0,0,0,0,0,0l1.5-1.5 c0.4-0.4,0.4-1,0-1.4l-8.9-9c0,0,0,0,0,0l-2.5-2.5c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l1.8,1.8l-7.7,7.7c-0.4,0.4-0.4,1,0,1.4 L6.4,16.7z M13.6,7L14,7.5c0,0,0,0,0,0l7,7L20.6,15H7.5l-1-1L13.6,7z"></path>
                          <path d="M25,24c1.7,0,3-1.3,3-3c0-1.4-1.8-3.2-2.3-3.7c-0.4-0.4-1-0.4-1.4,0C23.8,17.8,22,19.6,22,21C22,22.7,23.3,24,25,24z"></path>
                        </g>
                      </g>
                    </svg>

                    <span className="text-xs sm:text-base">Background</span>
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1">
                    <Popover.Panel as='form' className="absolute left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                      <Compact
                        color={hex_bg}
                        onChange={(color) => {
                          setHexBg(color.hex);
                          post.bg_color = color.hex;
                        }} />
                    </Popover.Panel>
                  </Transition>
                </>
              </Popover>
            </div>
          </li>
          <li className='mt-2'><span className="inline-block border-r border-slate-500 h-5"></span></li>
          <li className='ml-4 text-slate-500'>
            <div>
              <Popover className="relative">
                <>
                  <Popover.Button className='text-opacity-90 group inline-flex rounded-md bg-slate-500 px-3 py-2 text-sm font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
                    <svg
                      className="w-5 h-5 fill-current pr-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32 32"
                      xmlSpace="preserve">
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <g>
                          <path d="M29,27H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h26c0.6,0,1-0.4,1-1S29.6,27,29,27z"></path>
                          <path d="M5,24h4c0.6,0,1-0.4,1-1s-0.4-1-1-1H8.6l1.9-4h11.1l1.9,4H23c-0.6,0-1,0.4-1,1s0.4,1,1,1h4c0.6,0,1-0.4,1-1s-0.4-1-1-1 h-1.4L16.9,3.6C16.7,3.2,16.4,3,16,3s-0.7,0.2-0.9,0.6L6.4,22H5c-0.6,0-1,0.4-1,1S4.4,24,5,24z M16,6.3l4.6,9.7h-9.2L16,6.3z"></path>
                        </g>
                      </g>
                    </svg>
                    <span className="text-xs sm:text-base">Text Color</span>
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1">
                    <Popover.Panel as='form' className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                      <Compact
                        color={hex_txt}
                        onChange={(color) => {
                          setHexTxt(color.hex);
                          post.txt_color = color.hex;
                        }} />
                    </Popover.Panel>
                  </Transition>
                </>
              </Popover>
            </div>
          </li>
        </ul>
      </div>}

      {/* Customize Card */}
      <div className="mt-3">
        <VerseContext.Provider value={{ verse: post.verse, verse_info: post.info, revert_changes: cancel, edit: is_editable, hide: loading, bg_color: hex_bg, txt_color: hex_txt }}>
          <PosterCard ref={componentRef} />
        </VerseContext.Provider>
      </div>

      {/* Read more */}
      {!is_editable && <div className="flex flex-row mt-4 justify-center items-center">
        <button className="text-orange-600 hover:text-orange-900 dark:text-orange-400 dark:hover:text-orange-200 rounded-lg inline-flex"
          onClick={() => setPage({"loc": 'View', "obj": post})}>
          <span>Read more. . .</span>
        </button>
      </div>}

      {/* Apply, Revert */}
      {is_editable && <div className="mt-4">
        <div>
          <button className="border border-green-600 text-green-500 hover:text-green-700 font-medium rounded-lg text-sm text-center inline-flex ml-2 mr-2 p-2 pr-6"
            onClick={() => { setIsEditable(!is_editable); setCancel(false); revertChanges(false); }}>
            <CheckIcon className='w-5 h-5 pr-1' />
            <span>Apply</span>
          </button>
          <button className="border border-red-500 text-red-400 hover:text-red-700 font-medium rounded-lg text-sm text-center inline-flex ml-2 mr-2 p-2 pr-6"
            onClick={() => { setIsEditable(!is_editable); setCancel(true); revertChanges(true); }}>
            <XMarkIcon className='w-5 h-5 pr-1' />
            <span>Revert</span>
          </button>
        </div>
      </div>}
    </div>

  );
}

export default CardPage;