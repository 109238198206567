import React, { useState, useEffect, createContext, useContext, useRef } from 'react';

// Create a context for the theme
const ThemeContext = createContext();
const PageContext = createContext();
const StatContext = createContext();
const SearchContext = createContext();
const ProfileContext = createContext();


// Custom hook to access the theme context
const useThemeContext = () => {
    return useContext(ThemeContext);
};

// Custom hook to access the page context
const usePageContext = () => {
    return useContext(PageContext);
};

// Custom hook to access the stats
const useStatsContext = () => {
    return useContext(StatContext);
};

// Custom hook to access the search
const useSearchContext = () => {
    return useContext(SearchContext);
};


// Custom hook to access the profile
const useProfileContext = () => {
    return useContext(ProfileContext);
};



// ThemeProvider component
function ContextProvider({ children }) {

    // 1. Setting up Theme Context
    const [isDarkMode, setIsDarkMode] = useState(
        localStorage.getItem('color-theme') === 'dark' ||
        (!('color-theme' in localStorage) &&
            window.matchMedia('(prefers-color-scheme: dark)').matches)
    );

    const toggleDarkMode = () => {
        setIsDarkMode((prevIsDarkTheme) => !prevIsDarkTheme);
    };


    // 2. Setting up Page Context
    const [page, setPage] = useState({ "loc": 'Home', "obj": null }); // Home
    // const [page, setPage] = useState({ "loc": 'Explore', "obj": null }); // Explore
    const [enableStatsSorting, setEnableStatsSorting] = useState(false); // Right side bar


    // 3. Setting up Stats Context
    const [stats, setStats] = useState([]);
    const [batchNo, setBatchNo] = useState(1);
    const [initLoad, setInitLoad] = useState(0); // 0: load first batch, 1: load next batch, -1: load previous batch, 2: do nothing
    const [loadingInitialStats, setLoadingInitialStats] = useState(true); // show loading spinner while accessing server
    const [errorLoadingStats, setErrorLoadingStats] = useState(false); // show error message if server is not accessible
    const [orderStatsBy, setOrderStatsBy] = useState('recents'); // ['views', 'recents', 'downloads']
    const [loadBatchStatus, setLoadBatchStatus] = useState('empty'); // 'new', 'duplicate', 'empty'
    const [loadingBatchStats, setLoadingBatchStats] = useState(false); // show loading spinner while adding new stats

    const prevOrderStatsBy = useRef(orderStatsBy);
    const limitLoadSize = 7;

    // 4. Setting up Search Context
    const [bibleBooks, setBibleBooks] = useState([]);

    // 5. Setting up Profile Context
    const [profileExists, setProfileExists] = useState(false);
    const [profile, setProfile] = useState({});
    const [showUserHistory, setShowUserHistory] = useState(false);

    // 6. Initiate increment downloadCount
    const updateDownloadCount = (stat) => {
        if (!stat.downloadCounted) {
            //stat.downloadCount = stat.downloadCount + 1;
            const statsWorkerTemp = new Worker('workers/worker_stats.js');
            statsWorkerTemp.postMessage({ type: "download-count", payload: [stat.timestamp] });

            try {
                statsWorkerTemp.onmessage = function (message) {
                    const { type, data } = message.data;
                    if (type === 'download-count') {
                        const msg = data['status'];
                        // console.log(data);
                        if (msg === 'success') {
                            stat.downloadCounted = true;
                            stat.downloadCount = stat.downloadCount + 1; // DOES NOT UPDATE IN UI
                        } else {
                            console.log(msg['message']);
                        }
                    }
                }
            } catch (error) { console.log("Stat worker error: " + error); }

            // Terminate after 5 seconds
            setTimeout(() => {
                statsWorkerTemp.terminate();
                // console.log("download-count worker terminated!")
            }, 5000);
        } else { console.log("downloadCount already updated!"); }
        return stat;
    };

    // 7. Initiate increment viewCount
    const updateViewCount = (stat) => {
        if (!stat.viewCounted) {
            //stat.viewCount = stat.viewCount + 1;
            const statsWorkerTemp = new Worker('workers/worker_stats.js');
            statsWorkerTemp.postMessage({ type: "view-count", payload: [stat.timestamp] });

            try {
                statsWorkerTemp.onmessage = function (message) {
                    const { type, data } = message.data;
                    if (type === 'view-count') {
                        const msg = data['status'];
                        // console.log(data);
                        if (msg === 'success') {
                            stat.viewCounted = true;
                            stat.viewCount = stat.viewCount + 1; // DOES NOT UPDATE IN UI
                        } else {
                            console.log(msg['message']);
                        }
                    }
                }
            } catch (error) { console.log("Stat worker error: " + error); }

            // Terminate after 5 seconds
            setTimeout(() => {
                statsWorkerTemp.terminate();
                // console.log("view-count worker terminated!")
            }, 5000);
        } else { console.log("viewCount already updated!"); }
        return stat;
    };


    // Check local storage or user preference to initialize dark mode
    useEffect(() => {

        // 1. Set the theme
        if (isDarkMode) {
            document.documentElement.classList.add('dark');
            localStorage.setItem('color-theme', 'dark');
        } else {
            document.documentElement.classList.remove('dark');
            localStorage.setItem('color-theme', 'light');
        }

        // 2. Set the page and hide/show RightSideBar
        if (page === 'Home') {
            setEnableStatsSorting(true);
        } else {
            setEnableStatsSorting(false);
        }

        // 3. Prompt server to create new user profile
        // TO BE DELETED
        if (initLoad === 0) {
            setProfileExists(false);
            const userProfile = {
                username: "Anonymous", email: "admin@sharemiale.info.ke", location: "Kenya", gender: "Unknown",
                role: "user", status: "active", viewedPosts: [], downloadedPosts: [], likedPosts: []
            };
            setProfile(userProfile);
        }

        // 4. Load stats or load next batch using a Worker
        //statsWorker.current = new Worker('workers/worker_stats.js');
        if (initLoad === -1 || initLoad === 0 || initLoad === 1) {
            // -1: load previous batch 
            // 0: load first batch 
            // 1: load next batch
            // 2: do nothing

            // Post a message to the worker, to initiate the process
            const statsWorker = new Worker('workers/worker_stats.js');
            statsWorker.postMessage({ type: "stats", payload: [batchNo, limitLoadSize] });

            // Listen for messages from the worker
            try {
                statsWorker.onmessage = function (message) {
                    const { colors_data, stats_data } = message.data;

                    // Map the data and assign unique values based on the index
                    const lim = (batchNo - 1) * 7;
                    const newStats = Object.values(stats_data).map((item, index) => ({
                        ...item,
                        viewCounted: false,
                        downloadCounted: false,
                        bg_color: colors_data[(index + lim) % colors_data.length]["bgColor"],
                        txt_color: colors_data[(index + lim) % colors_data.length]["txtColor"]
                    }));

                    const lastNewStat = newStats[newStats.length - 1];
                    const lastStat = stats[stats.length - 1];

                    if (!lastNewStat) {
                        //console.log('No more stats');
                        setLoadBatchStatus('empty');
                    } else if (lastStat && lastNewStat.timestamp === lastStat.timestamp) {
                        //console.log('Duplicate');
                        setLoadBatchStatus('duplicate');
                    } else {
                        //console.log('Not Duplicate');
                        setLoadBatchStatus('new');
                        if (initLoad === 0) {
                            setStats(newStats);
                        } else if (initLoad === 1) {
                            setStats([...stats, ...newStats]);
                        } else if (initLoad === -1) {
                            // setStats([...newStats, ...stats]);
                        }
                    }
                    setInitLoad(2);
                    setLoadingBatchStats(false);
                    //console.log(newStats);
                };
            } catch (error) { console.log("Stat worker stats: " + error); }

            // Terminate after 10 seconds
            setTimeout(() => {
                statsWorker.terminate();
                // console.log("stats worker terminated!")
            }, 10000);
        }

        // 5. Order stats
        // Check if orderStatsBy has changed
        if (orderStatsBy !== prevOrderStatsBy.current) {
            const sortedStats = [...stats]; // Create a copy of stats
            if (orderStatsBy === 'recents') {
                sortedStats.sort((a, b) => b.timestamp - a.timestamp);
            } else if (orderStatsBy === 'views') {
                sortedStats.sort((a, b) => b.viewCount - a.viewCount);
            } else if (orderStatsBy === 'downloads') {
                sortedStats.sort((a, b) => b.downloadCount - a.downloadCount);
            }
            setStats(sortedStats);
        }
        prevOrderStatsBy.current = orderStatsBy; // Update the previous value of orderStatsBy

        // 6. Check if stats are loaded
        if (initLoad === 0) {
            setLoadingInitialStats(true);
        } else {
            setLoadingInitialStats(false);
        }

        // 7. Check if Stats server returned error (unable to load stats!)
        if (stats.length <= 0 && initLoad !== 0) {
            setErrorLoadingStats(true);
        } else {
            setErrorLoadingStats(false);
        }


        // 8. Load bible books (for Explore feature)
        if (bibleBooks.length <= 0) {
            fetch('data/bible_books.json')
                .then((response) => response.json())
                .then((data) => {
                    setBibleBooks(data);
                });
        }

        // Clean up the worker when the component unmounts
        /*return () => {
            console.log("AppContext has unmounted!")
        }*/

    }, [initLoad, isDarkMode, page, stats, orderStatsBy, batchNo, bibleBooks]);



    return (
        <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
            <PageContext.Provider value={{ page, setPage, enableStatsSorting, setEnableStatsSorting }}>
                <ProfileContext.Provider value={{ profileExists, profile, showUserHistory, setShowUserHistory }}>
                    <SearchContext.Provider value={{ bibleBooks }}>
                        <StatContext.Provider value={{
                            stats,
                            setInitLoad, loadingInitialStats, errorLoadingStats, loadingBatchStats,
                            setLoadingBatchStats, batchNo, setBatchNo, loadBatchStatus,
                            orderStatsBy, setOrderStatsBy,
                            updateDownloadCount, updateViewCount
                        }}>
                            {children}
                        </StatContext.Provider>
                    </SearchContext.Provider>
                </ProfileContext.Provider>
            </PageContext.Provider>
        </ThemeContext.Provider>
    );
}

export default ContextProvider;
export { useThemeContext, usePageContext, useStatsContext, useSearchContext, useProfileContext };

