import { useEffect, useState } from "react";


function ReadModule({ info, verse_arr }) {

    const [headerInfo, setHeaderInfo] = useState('');
    const [formattedVerses, setFormattedVerses] = useState(null);


    const formatVerses = (verse_arr) => {
        return (
            <p className="text-base text-justify leading-relaxed text-slate-500 dark:text-slate-300">
                {verse_arr.length > 0 ? verse_arr.map((verse) => (
                    <span key={verse.id}>
                        <sup className="text-xs text-orange-600 dark:text-orange-400 pr-0.5">{verse.id}</sup>{verse.text}<span> </span>
                    </span>
                )) : <span>No verses found!</span>}
            </p>
        );
    }

    useEffect(() => {

        setHeaderInfo(info);

        const fmt_verses = formatVerses(verse_arr);
        setFormattedVerses(fmt_verses);

    }, [info, verse_arr])

    return (
        <div className="flex flex-col mb-4 mx-4 justify-center items-center">
            {/* Header Info */}
            <div className="p-2">
                <h3 className="text-lg text-slate-700 dark:text-slate-100">{headerInfo}</h3>
            </div>

            {/* Display Bible verse */}
            <div className="pt-4">{formattedVerses}</div>
        </div>
    )

}

export default ReadModule;
