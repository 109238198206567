import React, { useRef, useEffect } from 'react';
import { SunIcon, MoonIcon, Bars3BottomLeftIcon } from '@heroicons/react/24/solid';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // Import the tippy CSS (or use your own styling)
import { useThemeContext } from './AppContext';


function NavBar() {

    const tooltipTheme = useRef(null);
    const { isDarkMode, toggleDarkMode } = useThemeContext();


    useEffect(() => {

        if (tooltipTheme.current) {
            tippy(tooltipTheme.current, {
                content: 'switch theme', // Tooltip content
                arrow: true, // Show an arrow for the tooltip
                placement: 'bottom', // Tooltip placement (top, bottom, left, right)
                animation: 'shift-toward', // Tooltip animation style
                // You can add more options and customize the tooltip appearance here
            });
        }
    }, []);


    return (
        <nav className="fixed top-0 start-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-slate-800 dark:border-slate-700">
            <div className="flex flex-wrap items-center justify-between mx-auto px-3 py-3 lg:px-5 lg:pl-3">

                {/* Left Elements: Logo */}
                <div className="flex">
                    <button
                        data-drawer-target="navbar-sticky"
                        data-drawer-toggle="navbar-sticky"
                        className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                        aria-controls="navbar-sticky"
                        aria-expanded="false">
                        <span className="sr-only">Open sidebar</span>
                        <Bars3BottomLeftIcon className="w-6 h-6" aria-hidden="true"/>
                    </button>
                    <a className="flex ml-2 md:mr-24" href="\#">
                        {isDarkMode ? (
                            <>
                                <img src="/images/logo_bigW.png" className="h-8 md:h-16 mr-3" alt="Sharemiale Logo" ></img>
                            </>
                        ) : (
                            <>
                                <img src="/images/logo_bigBO.png" className="h-8 md:h-16 mr-3" alt="Sharemiale Logo"></img>
                            </>
                        )}
                    </a>
                </div>

                {/* Right Elements: theme-switcher */}
                <div className="flex items-center">
                    <div className="text-black dark:text-white">
                        <button id="theme-toggle" onClick={toggleDarkMode} className="nav-link p-1 focus:outline-none hover:text-orange-200 focus:text-gray-500  dark:focus:text-gray-200" ref={tooltipTheme}>
                            {isDarkMode ? (
                                <>
                                    <span id="theme-toggle-light-icon" className="hidden">
                                        <SunIcon className="h-6 w-6" />
                                    </span>
                                    <span id="theme-toggle-dark-icon"><MoonIcon className="h-5 w-5" /></span>
                                </>
                            ) : (
                                <>
                                    <span id="theme-toggle-dark-icon" className="hidden">
                                        <MoonIcon className="h-5 w-5 " />
                                    </span>
                                    <span id="theme-toggle-light-icon"><SunIcon className="h-6 w-6" /></span>
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
}


export default NavBar;
