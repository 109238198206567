import React from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { usePageContext } from './AppContext';
import AboutUs from './pages/page_about';
import CardPage from './pages/page_card';
import ViewPage from './pages/page_view';
import SearchPage from './pages/page_search';
import StudyPage from './pages/page_study';
import StatsPage from './pages/page_stats';



function Main() {

    const { page, setPage } = usePageContext();

    return (
        <div className="p-4 md:ml-52 lg:ml-64 sm:mr-48 lg:mr-64 pt-28 bg-white dark:bg-slate-800">
            {/*<div className="p-4 md:ml-52 lg:ml-64 sm:mr-32 lg:mr-72 bg-white dark:bg-slate-800">*/}
            {/* About Page */}
            {(page['loc'] === 'About') &&
                <div className="min-h-screen max-w-4xl p-4">
                    <div className="flex justify-center mt-6">
                        <AboutUs />
                    </div>
                </div>}

            {/* Stats Page */}
            {(page['loc'] === 'Home') &&
                <div className="min-h-screen max-w-4xl p-4">{/*border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 */}
                    <div className="flex justify-center">
                        <StatsPage />
                    </div>
                </div>}


            {/* Card Page */}
            {(page['loc'] === 'Card') &&
                <div className="min-h-screen max-w-4xl p-4">
                    <div className="flex flex-col relative">
                        <button className="absolute top-0 left-0 p-2 rounded-full focus:outline-none text-blue-400 hover:bg-blue-200 hover:text-blue-600 dark:text-lime-600 dark:hover:bg-lime-100"
                            onClick={() => setPage({"loc": 'Home', "obj": null})}>
                            <ArrowLeftIcon className="w-6 h-6" />
                        </button>
                        <div className="flex justify-center mt-6">
                            <CardPage post={page['obj']} />
                        </div>
                    </div>
                </div>}


            {/* Explore Page */}
            {(page['loc'] === 'Explore') &&
                <div className="min-h-screen max-w-4xl p-4">
                    <div className="flex justify-center mt-6">
                        <SearchPage />
                    </div>
                </div>}


            {/* Bible Study Page */}
            {(page['loc'] === 'Study') &&
                <div className="min-h-screen max-w-4xl p-4">
                    <div className="flex justify-center mt-6">
                        <StudyPage />
                    </div>
                </div>}



            {/* Read Page */}
            {(page['loc'] === 'View') &&
                <div className="min-h-screen max-w-4xl p-4">
                    <div className="flex flex-col relative">
                        <button className="absolute top-0 left-0 p-2 rounded-full focus:outline-none text-blue-400 hover:bg-blue-200 hover:text-blue-600 dark:text-lime-600  dark:hover:bg-lime-100"
                            onClick={() => setPage({"loc": 'Card', "obj": page['obj']})}>
                            <ArrowLeftIcon className="w-6 h-6" />
                        </button>
                        <div className="flex justify-center mt-6">
                            <ViewPage from_index={page['obj'].init_index} to_index={page['obj'].last_index} onNotify={()=>{}} />
                        </div>
                    </div>
                </div>}


            {/* Profile Page */}
            {(page['loc'] === 'Profile') &&
                <div className="min-h-screen max-w-4xl p-4">
                    <div className="flex justify-center mt-6">
                        <p className="text-lg text-slate-500 dark:text-slate-200">Create user profile (coming soon ...)</p>
                    </div>
                </div>}

        </div>

    );

}

export default Main;