import { StrictMode } from "react";
import ContextProvider from './js/AppContext';
import NavBar from "./js/navbar";
import Footer from "./js/footer";
import Main from "./js/home";
import SideBar from "./js/components/sidebar_left";
import SideBarRight from "./js/components/sidebar_right";


function MainContent() {

  return (
    <StrictMode>
      <ContextProvider>
        <NavBar />
        <SideBar />
        <Main />
        <SideBarRight />
        <Footer />
      </ContextProvider>
    </StrictMode>
  );
}

export default MainContent;
