import { useEffect, useRef, useState } from "react";
import { Flowbite, Modal } from 'flowbite-react';
import { ChevronUpIcon, InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useSearchContext } from "../AppContext";
import ViewPage from "./page_view";


function SearchPage() {

    const { bibleBooks } = useSearchContext();
    const [notify, setNotify] = useState(false);
    const [notification, setNotification] = useState('');
    const [selectedSearchOption, setSelectedSearchOption] = useState('verses'); // 'verses', 'phrases'
    const [placeholderText, setPlaceholderText] = useState('Search');

    const [searchItem, setSearchItem] = useState('');
    const [verseInfo, setVerseInfo] = useState('');
    const [selectedBook, setSelectedBook] = useState('');
    const [selectedBookItem, setSelectedBookItem] = useState({});
    const [selectedChapter, setSelectedChapter] = useState(1);
    const [selectedFrom, setSelectedFrom] = useState(1);
    const [selectedTo, setSelectedTo] = useState(2);
    const [verseRangeSize, setVerseRangeSize] = useState(0);
    const [searchErrorText, setSearchErrorText] = useState('');
    const [filteredBooks, setFilteredBooks] = useState([]);
    const [fromVerseIndex, setFromVerseIndex] = useState(-1);
    const [toVerseIndex, setToVerseIndex] = useState(-1);

    const [isVersesOption, setIsVersesOption] = useState(true);
    const [isOptionsDropdownOpen, setIsOptionsDropdownOpen] = useState(false);
    const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hideEdit, setHideEdit] = useState(false);
    const [showResults, setShowResults] = useState(false);

    const prevSelectedChapter = useRef(null);
    const prevSelectedFrom = useRef(null);


    const toggleOptionsDropdown = () => {
        setIsOptionsDropdownOpen(!isOptionsDropdownOpen);
    };

    const toggleSearchDropdown = () => {
        setIsSearchDropdownOpen(!isSearchDropdownOpen);
    };

    const handleSearchOptionChange = (e) => {
        setSelectedSearchOption(e.target.value);
        toggleOptionsDropdown();
    };

    const handleSearchInputChange = (e) => {
        const searchTerm = e.target.value;
        setSearchItem(searchTerm)

        // filter the items using the bibleBooks state
        const filteredItems = bibleBooks.filter((book) =>
            book.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredBooks(filteredItems);
    }

    const handleViewVerseChange = (data) => {
        if (data.error_msg === '') {
            setNotification('');
            setNotify(false);
            setVerseInfo(data.info);
        } else {
            setNotification(data.error_msg);
            setNotify(true);
        }
    }

    const editSearchItem = () => {
        setVerseInfo('');
        setSelectedBookItem({});
        setHideEdit(false);
    }

    const getRange = (size, startAt) => {
        return [...Array(size).keys()]
            .map(i => ((i + startAt) <= size) ? i + startAt : null)
            .filter(val => val !== null);
    }


    const searchByVerse = () => {

        const verse = selectedBook + ' ' + selectedChapter + ': ' + selectedFrom + ' - ' + selectedTo;
        setVerseInfo(verse);
        setIsModalOpen(false);
        setHideEdit(true);
        setShowResults(false);
        let success = false;

        const searchWorkerTemp = new Worker('workers/worker_explore.js');
        searchWorkerTemp.postMessage({ type: 'search-verses', payload: [selectedBookItem.id, selectedChapter, selectedFrom, selectedTo] });

        try {
            // console.log("Search verse worker is ready.")
            searchWorkerTemp.onmessage = function (message) {
                const { task, data } = message.data;

                if (task === 'search-verses') {
                    success = true;
                    const verse_data = data[0];
                    const status_msg = verse_data[0];
                    const msg = verse_data[1];
                    const f_idx = parseInt(verse_data[2]);
                    const t_idx = parseInt(verse_data[3]);
                    //console.log(data);

                    if (status_msg === 'success') {
                        setFromVerseIndex(f_idx);
                        setToVerseIndex(t_idx);
                        setShowResults(true);
                    } else {
                        setNotification(msg);
                        setNotify(true);
                        setShowResults(false);
                        console.log(verse_data);
                    }
                }
            }
        } catch (error) { console.log("Search worker verse-error: " + error); }

        // Terminate after 6 seconds
        setTimeout(() => {
            searchWorkerTemp.terminate();
            if (!success) {
                setNotification('Time out expired, try again.');
                setNotify(true);
                setShowResults(false);
            }
            //console.log("search-verse worker Terminated!")
        }, 6000);
    }


    /*const searchByPhrase = () => {

        let success = false;

        const searchWorkerTemp = new Worker('workers/worker_explore.js');
        searchWorkerTemp.postMessage({ type: 'search-phrases', payload: [] });

        try {
            // console.log("Search verse worker is ready.")
            searchWorkerTemp.onmessage = function (message) {
                const { task, data } = message.data;

                if (task === 'search-phrases') {
                    success = true;
                    const verse_data = data[0];
                    const status_msg = verse_data[0];
                    const msg = verse_data[1];
                    //console.log(data);

                    if (status_msg === 'success') {
                        
                    } else {
                        setNotification(msg);
                        setNotify(true);
                    }
                }
            }
        } catch (error) { console.log("Search worker search-error: " + error); }

        // Terminate after 6 seconds
        setTimeout(() => {
            searchWorkerTemp.terminate();
            if (!success) {
                setNotification('Time out expired, try again.');
                setNotify(true);
            }
            //console.log("search-phrase worker Terminated!")
        }, 6000);

    }*/

    useEffect(() => {
        setSearchErrorText('No books found!'); // to be changed later

        if (bibleBooks.length > 0) {
            setFilteredBooks(bibleBooks);
        }

        if (selectedSearchOption === 'verses') {
            setIsVersesOption(true);
        } else {
            setIsVersesOption(false);
        }

        if (Object.keys(selectedBookItem).length > 0) {
            setSelectedBook(selectedBookItem.name);
            setVerseRangeSize(parseInt(selectedBookItem.chapters[1]));
            if (selectedSearchOption === 'verses') {
                setPlaceholderText('');
            }
        } else {
            setSelectedBook('');
            if (selectedSearchOption === 'verses') {
                setPlaceholderText('Search e.g., Matthew 3:1-4');
            }
        }

        if (selectedSearchOption === 'phrases') {
            setPlaceholderText('Search e.g., Jesus walked on water');
        } else if ((selectedSearchOption !== 'phrases') && (selectedSearchOption !== 'verses')) {
            setPlaceholderText('Search');
            setNotification('Select one option');
        }

        // Check if selectedChapter has changed
        if ((Object.keys(selectedBookItem).length > 0) && (selectedChapter !== prevSelectedChapter.current)) {

            const chapObj = Object.entries(selectedBookItem.chapters).find(([k, v]) => k === selectedChapter);
            setVerseRangeSize(parseInt(chapObj[1]));
            setSelectedFrom(1);
        }
        prevSelectedChapter.current = selectedChapter; // Update the value


        if (prevSelectedFrom.current !== selectedFrom) {
            setSelectedTo(selectedFrom + 1);
        }
        prevSelectedFrom.current = selectedFrom;

        return () => {
            // console.log("Search component unmounted!");
        }

    }, [selectedSearchOption, bibleBooks, selectedBookItem, showResults, selectedChapter, selectedFrom]);

    return (
        <div className="w-full h-full flex flex-col justify-center items-center">
            {/* Alert Section */}
            <div className="">
                {/* show notifications */}
                {notify &&
                    <div className="flex flex-row max-w-sm p-4 mb-4 text-rose-800 rounded-lg bg-rose-50 dark:bg-slate-600 dark:text-rose-200 hover:scale-110">
                        <InformationCircleIcon className="w-4 h-4" />
                        <div className="mx-2 text-sm">
                            {notification}
                        </div>

                        <button className="ml-auto -mx-1.5 -my-1.5 focus:outline-none bg-rose-50 text-rose-400 rounded-lg p-1.5 hover:bg-rose-200 dark:bg-slate-600 dark:text-rose-200 dark:hover:bg-gray-700"
                            onClick={() => setNotify(false)}>
                            <XMarkIcon className="w-5 h-5" />
                        </button>
                    </div>}

                {/* placeholder when notifications are hidden */}
                {!notify &&
                    <div className="p-6 mb-5">
                        <span className=""> </span>
                    </div>}
            </div>

            {/* Search Section */}
            <div className="w-full">
                <div className="flex justify-center">
                    <div className="flex-shrink-0 h-12">
                        <button
                            id="dropdown-button"
                            onClick={toggleOptionsDropdown}
                            className="z-10 w-full h-full inline-flex items-center py-2 px-4 text-base text-white border rounded-l-lg focus:outline-none bg-rose-500 border-rose-500 hover:bg-rose-600 dark:bg-yellow-200 dark:border-yellow-200 dark:text-gray-700  dark:hover:bg-yellow-100">
                            {selectedSearchOption}
                            <ChevronUpIcon className={`w-5 h-5 ml-2 transition-transform ${isOptionsDropdownOpen ? 'transform rotate-180' : ''}`} />
                        </button>
                        <div
                            id="dropdown"
                            className={`z-10 mt-2 w-fit ${isOptionsDropdownOpen ? '' : 'hidden'} rounded-lg shadow bg-rose-100 dark:bg-yellow-200`}>
                            <ul className="font-thin text-base text-rose-700 dark:text-gray-600" aria-labelledby="dropdown-button">
                                <li>
                                    <button className="w-full px-6 py-2 rounded-lg hover:bg-rose-500 hover:text-white dark:hover:bg-yellow-100 dark:hover:text-lime-600"
                                        value="verses"
                                        onClick={handleSearchOptionChange}>
                                        verses
                                    </button>
                                </li>
                                <li>
                                    <button className="w-full px-6 py-2 rounded-lg hover:bg-rose-500 hover:text-white dark:hover:bg-yellow-100 dark:hover:text-lime-600"
                                        value="phrases"
                                        onClick={handleSearchOptionChange}>
                                        phrases
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex-grow">
                        <div className="relative overflow-x-auto">
                            <input
                                className="z-20 min-w-full h-12 py-2 px-4 text-sm placeholder-slate-400 text-slate-500 bg-blue-50 border border-l-0 rounded-r-lg border-blue-400 focus:border-l-0 focus:rounded-r-lg focus:ring-0 focus:border-2 focus:border-blue-400 dark:bg-slate-600 dark:border-stone-400 dark:focus:border-stone-400 dark:placeholder-gray-400 dark:text-white"
                                type="text"
                                value={searchItem}
                                onChange={handleSearchInputChange}
                                onFocus={toggleSearchDropdown}
                                placeholder={placeholderText}
                            />
                            {selectedBook !== '' && isVersesOption &&
                                <div className="absolute inset-y-0 left-0 flex flex-row">
                                    <div className="flex flex-row justify-center items-center m-2 pl-2 text-xs rounded-md bg-slate-200 text-gray-500 dark:bg-stone-200 dark:text-slate-500">
                                        <span className="pr-1">{verseInfo === '' ? selectedBook : verseInfo}</span>
                                        <button className="h-full focus:outline-none rounded-md hover:text-green-700 hover:bg-lime-200 dark:hover:text-slate-400"
                                            onClick={editSearchItem}>
                                            <XMarkIcon className="w-5 h-5 py-1" />
                                        </button>
                                    </div>
                                    {!hideEdit &&
                                        <button className="flex flex-col justify-center focus:outline-none mr-1 text-slate-700 dark:text-stone-300 hover:text-blue-500 dark:hover:text-stone-50"
                                            onClick={() => setIsModalOpen(true)}>
                                            <span className="text-xs font-thin">select</span>
                                            <span className="text-xs font-thin">chapter</span>
                                        </button>}
                                </div>}
                            {/*<div className="absolute inset-y-0 right-0 flex items-center mr-2">
                                <MagnifyingGlassIcon className="w-5 h-5 text-slate-600 dark:text-stone-100 hover:text-blue-500 dark:hover:text-stone-400" />
                            </div>*/}
                        </div>
                        {isVersesOption &&
                            <div className={`z-20 w-full mt-2 ${isSearchDropdownOpen ? '' : 'hidden'} rounded-lg shadow bg-slate-200 dark:bg-slate-200`}>
                                {filteredBooks.length === 0
                                    ? <p className="h-fit mx-4 my-2 py-4 font-thin text-base text-blue-800 dark:text-stone-700">{searchErrorText}</p>
                                    : <ul className="max-h-32 my-2 overflow-y-auto font-thin text-sm text-blue-800 dark:text-stone-700">
                                        {selectedBook === '' && filteredBooks.map(book =>
                                            <li className="w-full px-2 py-2 rounded-md hover:bg-blue-500 hover:text-white dark:hover:bg-slate-500 dark:hover:text-white"
                                                onClick={() => setSelectedBookItem(book)}
                                                key={book.id}>{book.name}</li>)}
                                    </ul>}
                            </div>}

                        {!isVersesOption &&
                            <div className="w-full">
                                <p className="p-6 text-green-500 dark:text-green-100 font-medium text-base">Comming soon!</p>
                            </div>}
                    </div>
                </div>

                {/* Modal (Popup) Functionality */}
                <Flowbite>
                    <Modal
                        show={isModalOpen === true}
                        onClose={() => setIsModalOpen(false)}>
                        <Modal.Header>
                            <div className="flex justify-center items-center">
                                <p className="text-slate-600 dark:text-gray-200">Select verses</p>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="space-y-2 flex flex-col justify-center items-center">
                                <p className="text-lg text-slate-600 dark:text-gray-200">{selectedBookItem.name}</p>
                                {Object.keys(selectedBookItem).length > 0 &&
                                    <div className="w-fit flex flex-col">
                                        <div className="grid grid-cols-2 gap-2 text-sm font-thin text-slate-600 dark:text-slate-100">
                                            <span className="">chapter</span>
                                            <select className="rounded-md bg-white text-slate-500 focus:ring-0 dark:text-slate-100 dark:bg-slate-700"
                                                onChange={(e) => setSelectedChapter(e.target.value)}
                                                value={selectedChapter}>
                                                {Object.entries(selectedBookItem.chapters).map(([key, value]) => (
                                                    <option key={key} value={key}
                                                        className="text-center">
                                                        {key}
                                                    </option>
                                                ))}
                                            </select>

                                            <span className="">verse</span>
                                            <select className="rounded-md bg-white text-slate-500 focus:ring-0 dark:text-slate-100 dark:bg-slate-700"
                                                onChange={(e) => setSelectedFrom(parseInt(e.target.value))}
                                                value={selectedFrom}>
                                                {getRange(verseRangeSize, 1).map((val) => (
                                                    <option key={val} value={val}
                                                        className="text-center">
                                                        {val}
                                                    </option>
                                                ))}
                                            </select>

                                            <span className="">to</span>
                                            <select className="rounded-md bg-white text-slate-500 focus:ring-0 dark:text-slate-100 dark:bg-slate-700"
                                                onChange={(e) => setSelectedTo(parseInt(e.target.value))}
                                                value={selectedTo}>
                                                {getRange(verseRangeSize, (selectedFrom + 1)).map((val) => (
                                                    <option key={val} value={val}
                                                        className="text-center">
                                                        {val}
                                                    </option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="w-full flex items-center justify-center">
                                <button className="text-sm px-5 py-2.5 font-medium rounded-lg text-white bg-blue-700 hover:bg-blue-800 focus:ring-0 dark:bg-blue-600 dark:hover:bg-blue-700"
                                    onClick={searchByVerse}>
                                    Search
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </Flowbite>

                {/* Display found verses (in a formatted structure) */}
                {showResults && isVersesOption && hideEdit &&
                    <div className="flex justify-center">
                        <ViewPage from_index={fromVerseIndex} to_index={toVerseIndex} onNotify={handleViewVerseChange} hide_alerts={true} />
                    </div>}
            </div>
        </div>
    );

}

export default SearchPage;