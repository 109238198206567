import { useState, useEffect } from 'react';
import { Squares2X2Icon, EnvelopeIcon } from '@heroicons/react/24/solid';
import { BookOpenIcon, InformationCircleIcon, MagnifyingGlassIcon, UserIcon } from '@heroicons/react/24/outline';
import { useStatsContext, usePageContext, useProfileContext } from '../AppContext';

// Make SideBar Scrollable with Profile fixed at the bottom
function SideBar() {

    const { setPage } = usePageContext();
    const { stats } = useStatsContext();
    const { profile } = useProfileContext();
    const [statCount, setStatCount] = useState(0)
    const [showStat, setShowStat] = useState(true);

    const loadHome = () => {
        setPage({ "loc": 'Home', "obj": null });
        setShowStat(false);
    }

    useEffect(() => {
        setStatCount(Object.keys(stats).length)
    }, [stats]);

    return (

        <div id="navbar-sticky" className="fixed h-screen top-0 left-0 z-40 w-52 lg:w-64 pt-28 transition-transform -translate-x-full md:translate-x-0 bg-white dark:bg-slate-800 border-r border-gray-200 dark:border-gray-700">
            <div className="flex flex-col h-full justify-between px-3 pb-4 overflow-y-auto bg-white dark:bg-slate-800">
                <ul className="space-y-2 font-medium">
                    <li>
                        <a href="\#" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                            onClick={loadHome}>
                            <Squares2X2Icon className="flex-shrink-0 w-5 h-5 sm:w-6 sm:h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                            <span className="flex-1 ml-2 whitespace-nowrap">Home</span>
                            {showStat && <span className="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium rounded-full text-slate-500 bg-orange-200  dark:bg-yellow-200 dark:text-slate-600">{statCount}</span>}
                        </a>
                    </li>
                    <li>
                        <a href="\#" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                            onClick={() => setPage({ "loc": 'Explore', "obj": null })}>
                            <MagnifyingGlassIcon className="flex-shrink-0 w-5 h-5 sm:w-6 sm:h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                            <span className="flex-1 ml-2 whitespace-nowrap">Explore</span>
                        </a>
                    </li>
                    <li>
                        <a href="\#" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                            onClick={() => setPage({ "loc": 'Study', "obj": null })}>
                            <BookOpenIcon className="flex-shrink-0 w-5 h-5 sm:w-6 sm:h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                            <span className="flex-1 ml-2 whitespace-nowrap">Bible Study</span>
                        </a>
                    </li>
                    <li>
                        <a href="\#" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                            onClick={() => setPage({ "loc": 'About', "obj": null })}>
                            <InformationCircleIcon className="flex-shrink-0 w-5 h-5 sm:w-6 sm:h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                            <span className="flex-1 ml-2 whitespace-nowrap">About</span>
                        </a>
                    </li>
                    <li>
                        <a href="\#" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                            onClick={handleContactUs}>
                            <EnvelopeIcon className="flex-shrink-0 w-5 h-5 sm:w-6 sm:h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                            <span className="flex-1 ml-2 whitespace-nowrap">Contact Us</span>
                        </a>
                    </li>
                </ul>
                <ul className="space-y-2 font-medium">
                    <li>
                        <a href="\#" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                            onClick={() => setPage({ "loc": 'Profile', "obj": null })}>
                            <span className="flex-shrink-0 mr-2">{profile.username}</span>
                            <UserIcon className="flex-1 w-4 h-4 sm:w-5 sm:h-5 text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );

}

const handleContactUs = () => {
    window.open('mailto:admin@sharemiale.info.ke', 'Popup');
};

export default SideBar;