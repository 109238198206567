
import { useEffect, useState } from "react";
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";


function StudyPage() {

    const [notify, setNotify] = useState(false);
    const [notification, setNotification] = useState('');

    useEffect(() => {
        setNotification('Bible study capability Coming soon ...');
        setNotify(true);
    }, []);

    return (
        <div className="flex flex-col max-w-sm items-center">
            {/* Alert Section */}
            {notify && <div className="flex items-center p-4 mb-4 text-red-800 rounded-lg bg-red-50 dark:bg-slate-600 dark:text-red-200 hover:scale-110">
                <InformationCircleIcon className="w-4 h-4" />
                <div className="mx-2 text-sm">
                    {notification}
                </div>

                <button className="ml-auto -mx-1.5 -my-1.5 focus:outline-none bg-red-50 text-red-400 rounded-lg p-1.5 hover:bg-red-200 dark:bg-slate-600 dark:text-red-200 dark:hover:bg-gray-700"
                    onClick={() => setNotify(false)}>
                    <XMarkIcon className="w-5 h-5" />
                </button>
            </div>}

            {/* Search Section */}
        </div>
    );

}

export default StudyPage;