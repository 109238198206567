import { useThemeContext } from "../AppContext";


function AboutUs() {

    const { isDarkMode } = useThemeContext();

    return (
        <div className="flex flex-col max-w-xl items-center">
            <div className="">
                <p className="text-base text-justify text-lime-700 dark:text-lime-200">
                    And God said, <em><q>Let there be light,</q></em> and there was light. God saw that the light was good, and he separated the light from the darkness.
                </p>
                <span className="text-left font-medium text-lime-800 dark:text-lime-100 mt-1">&mdash; Genesis 1:3-4 (NIV)</span>
            </div>

            <div className="mt-12">
                {isDarkMode && <img className="py-4 px-4 w-72 sm:w-96"
                    src="images/sharemiale_GG.png" alt="">
                </img>}
                {!isDarkMode && <img className="py-4 px-4 w-64 sm:w-96"
                    src="images/sharemiale_BW.png" alt="">
                </img>}
            </div>

            <div className="mt-12">
                <p className="text-lg text-justify text-slate-500 dark:text-slate-200 ">
                    Sharemiale is a compound of two words: an English word <strong><q>share</q></strong> and a Swahili word <strong><q>miale</q></strong> and the full meaning is
                    <em className="dark:text-yellow-400 text-orange-500"> <q id="b">share the light of God.</q> </em>
                    In 2013, <a className="dark:text-blue-200 dark:hover:text-blue-400 text-blue-500 hover:text-blue-800" href="https://twitter.com/dicksonowuor" target="_blank" rel="noreferrer">Dickson Owuor (Ph.D.)</a> founded Sharemiale with the goal of building and offering digital platforms that allow people to share God's light in an authentic and loving manner.
                </p>
            </div>

            <div className="mt-2">
                {isDarkMode && <img className="w-10" src="images/icon_light.png" title="Sharemiale" alt="Sharemiale"></img>}
                {!isDarkMode && <img className="w-10" src="images/icon_dark.png" title="Sharemiale" alt="Sharemiale"></img>}
            </div>

            <div className="mt-12">
                <p className="text-xs font-thin text-center text-slate-800 dark:text-slate-400">
                    All scripture quotations, unless otherwise indicated, are taken from the HOLY BIBLE, World English Bible (WEB) version.
                </p>
            </div>
        </div>
    );

}

export default AboutUs;