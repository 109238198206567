import { useCallback, useEffect, useState } from "react";
import { BackwardIcon, ForwardIcon, InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { BookOpenIcon } from "@heroicons/react/24/solid";
import ReadModule from "../components/module_read";


function ViewPage({ from_index, to_index, onNotify, hide_alerts = false }) {

  const [initLoad, setInitLoad] = useState(true);
  const [notify, setNotify] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [notification, setNotification] = useState('');
  const [verses, setVerses] = useState({ "arr": [], "info": '', "init_index": -1, "last_index": -1 });
  const [fetchingMore, setFetchingMore] = useState(true);
  const [fetchedWholeChapter, setFetchedWholeChapter] = useState(false);

  const fetchMoreVerses = useCallback((f_idx, t_idx, dir) => {

    setFetchingMore(true);
    setNotify(false);
    let success = false;
    const searchWorkerTemp = new Worker('workers/worker_explore.js');
    searchWorkerTemp.postMessage({ type: 'read-more', payload: [f_idx, t_idx, dir] });

    // Listen for messages from the worker
    try {
      searchWorkerTemp.onmessage = function (message) {
        const { task, data } = message.data;
        //console.log(data);
        if (task === 'read-more') {
          success = true;
          const verse_data = data[0];
          const direction = data[1];
          const status_msg = verse_data[0];

          if (status_msg === 'end') {
            setNotification('No more verses to fetch.');
            onNotify({ error_msg: 'No more verses to fetch.', info: '' });
            setNotify(true);
          } else if ((status_msg === 'success') || (status_msg === 'warning')) {
            setVerses({ "arr": verse_data[1], "info": verse_data[2], "init_index": verse_data[3], "last_index": verse_data[4] });
            onNotify({ error_msg: '', info: verse_data[2] });
          } else {
            setNotification('Critical error!');
            onNotify({ error_msg: 'Unable to find verses!', info: '' });
            setNotify(true);
          }

          if (direction === 'full') {
            setFetchedWholeChapter(true);
          }
          setFetchingMore(false);
        }
      }
    } catch (error) { console.log("Search worker view-error: " + error); }

    // Terminate after 5 seconds
    setTimeout(() => {
      searchWorkerTemp.terminate();
      if (!success) {
        setNotification('Time out expired, try again.');
        setNotify(true);
        setFetchingMore(false);
      }
      //console.log("search-view worker Terminated!")
    }, 5000);

  }, [onNotify]);


  useEffect(() => {

    if (initLoad) {
      setInitLoad(false);
      fetchMoreVerses(from_index, to_index, 'same');
    }

    if (verses.arr.length > 0) { setSuccessful(true); }

  }, [initLoad, verses, from_index, to_index, fetchMoreVerses]);

  return (
    <div className="flex flex-col max-w-lg">
      {/* Alert Section */}
      <div className="">
        {/* show notifications */}
        {notify && !hide_alerts &&
          <div className="flex items-center p-4 mb-4 text-blue-800 rounded-lg bg-blue-50 dark:bg-slate-600 dark:text-blue-400 animate-pulse hover:scale-110">

            <InformationCircleIcon className="w-4 h-4" />
            <div className="mx-2 text-sm">
              {notification}
            </div>

            <button className="ml-auto -mx-1.5 -my-1.5 focus:outline-none bg-blue-50 text-blue-400 rounded-lg p-1.5 hover:bg-blue-200 dark:bg-slate-600 dark:text-blue-400 dark:hover:bg-gray-700"
              onClick={() => setNotify(false)}>
              <XMarkIcon className="w-5 h-5" />
            </button>
          </div>}

        {/* placeholder when notifications are hidden */}
        {!notify &&
          <div className="p-6 mb-5">
            <span className=""> </span>
          </div>}
      </div>


      {/* Read/View Verses Section */}
      <div className="flex-grow py-6">
        {!fetchingMore &&
          <div className='flex flex-col'>

            {/* Display Bible verse for reading */}
            <ReadModule info={verses.info} verse_arr={verses.arr} />

            {/* Display 'previous' and 'next' navigation */}
            {!fetchedWholeChapter && successful &&
              <div className="flex justify-between">
                <button className="flex items-center justify-center p-2 focus:outline-none text-blue-400 hover:text-blue-600 dark:text-lime-400 dark:hover:text-lime-100 rounded-lg"
                  onClick={() => fetchMoreVerses(verses.init_index, verses.last_index, 'prev')}>
                  <BackwardIcon className="w-5 h-5" />
                  <span className="pl-1"> previous</span>
                </button>

                <button className=" flex items-center justify-center p-2 focus:outline-none text-blue-400 hover:text-blue-600 dark:text-lime-400 dark:hover:text-lime-100 rounded-lg"
                  onClick={() => fetchMoreVerses(verses.init_index, verses.last_index, 'next')}>
                  <span className="pr-1">next </span>
                  <ForwardIcon className="w-5 h-5" />
                </button>
              </div>}

            {/* Display button to load 'whole chapter' */}
            {!fetchedWholeChapter && successful &&
              <div className="flex justify-center mt-1">
                <button className=" flex items-center justify-center focus:outline-none text-blue-400 hover:text-blue-600 dark:text-lime-400 dark:hover:text-lime-100 rounded-lg"
                  onClick={() => fetchMoreVerses(verses.init_index, verses.last_index, 'full')}>
                  <div className="flex flex-row items-center">
                    <BookOpenIcon className="w-6 h-6" />
                    <ul className="ml-1">
                      <li className="mb-0 text-left text-xs leading-none">full</li>
                      <li className="mb-0 text-left text-xs leading-none">chapter</li>
                    </ul>
                  </div>
                </button>
              </div>}

          </div>}

        {fetchingMore && <div className="text-center my-auto">
          <div role="status">
            <svg aria-hidden="true" className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-orange-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
            </svg>
            <span className="sr-only">Loading...</span>
            <p className="text-base text-slate-500 dark:text-slate-200">please wait ... </p>
          </div>
        </div>}
      </div>


      {/* Footer Section */}
      <div className="p-2 pt-4">
        {!fetchingMore && successful &&
          <p className="text-xs text-center text-slate-400">
            All scripture quotations, unless otherwise indicated, are taken from the World English Bible (WEB).
          </p>}
      </div>
    </div>
  );

}


export default ViewPage;
