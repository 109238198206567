import { useEffect, useState } from "react";
import tinycolor from 'tinycolor2';
import { tw } from 'twind';
import { CloudArrowDownIcon, EyeIcon } from "@heroicons/react/24/outline";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


function computeAge(stamp) {
    const now = new Date();
    const duration = Math.abs(now.getTime() - stamp);

    const seconds = Math.floor(duration / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);

    const months = Math.floor(days / 30); // Approximation, not precise
    const years = Math.floor(months / 12); // Approximation, not precise

    if (years > 0) return `${years} year${years > 1 ? 's' : ''} ago`;
    if (months > 0) return `${months} month${months > 1 ? 's' : ''} ago`;
    if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
}

function PreviewCard({ post }) {

    const [title_color, setTitleColor] = useState("#666666");

    const truncateText = (text, limit) => {
        const words = text.split(' ');
        if (words.length <= limit) {
            return text;
        }
        return words.slice(0, limit).join(' ') + ' . . .';
    };

    useEffect(() => {
        const is_dark_bg = tinycolor(post.bg_color).isDark();

        if (is_dark_bg) {
            //console.log("Dark color");
            setTitleColor("#FFFFFF");
        } else {
            //console.log("Light color");
            setTitleColor("#000000");
        }

    }, [post.bg_color]);

    return (
        <div className="max-w-lg flex flex-col">
            {/* Preview Card */}
            <div className={classNames('flex flex-col py-6 px-4 rounded-xl border', tw`border-[${post.bg_color}]`, tw`bg-[${post.bg_color}]`)}>
                <div className="flex-none pb-4">
                    <h4 className={classNames('text-base sm:text-lg', tw`text-[${title_color}]`)}>{post.info + ' (WEB)'}</h4>
                </div>

                <div className="grow">
                    <p className={classNames('text-sm md:text-base text-left transition-opacity duration-300', tw`text-[${post.txt_color}]`)}>
                        {truncateText(post.verse, 36)}
                    </p>
                </div>
            </div>

            {/* Stat Info */}
            <div className="flex justify-center items-center">
                <ul className="flex flex-row">
                    <li className="flex flex-row p-2 transition duration-75 text-slate-500 dark:text-slate-400 hover:text-slate-900 dark:hover:text-slate-100">
                        <p className="text-xs">
                            {computeAge(post.timestamp)}
                        </p>
                    </li>
                    <li className="">
                        <p className="text-gray-900 dark:text-white text-2xl">&middot;</p>
                    </li>
                    <li className="flex flex-row p-2 transition duration-75 text-slate-500 dark:text-slate-400 hover:text-slate-900 dark:hover:text-slate-100">
                        <CloudArrowDownIcon className="w-4 h-4 " />
                        <span className="ml-1 text-xs">{post.downloadCount}</span>
                    </li>
                    <li className="">
                        <p className="text-gray-900 dark:text-white text-2xl">&middot;</p>
                    </li>
                    <li className="flex flex-row p-2 transition duration-75 text-slate-500 dark:text-slate-400 hover:text-slate-900 dark:hover:text-slate-100">
                        <EyeIcon className="w-4 h-4" />
                        <span className="ml-1 text-xs">{post.viewCount}</span>
                    </li>
                </ul>
            </div>
        </div>
    );

}

export default PreviewCard;