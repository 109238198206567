import { ArrowTrendingUpIcon, CheckCircleIcon, CloudArrowDownIcon, EyeIcon } from "@heroicons/react/24/outline";
//import { RadioGroup } from '@headlessui/react'
import { usePageContext, useProfileContext, useStatsContext } from "../AppContext";


function SideBarRight() {

    const { enableStatsSorting } = usePageContext();
    const { orderStatsBy, setOrderStatsBy } = useStatsContext();
    const { profileExists, profile } = useProfileContext();

    const handleOrderByChange = (e) => {
        setOrderStatsBy(e.target.value);
    };

    return (

        <div className="fixed h-screen top-0 right-0 z-40 w-48 lg:w-64 pt-32 pr-4 sm:block hidden bg-white dark:bg-slate-800">
            <div className="flex justify-start">
                {enableStatsSorting &&
                    <ul className="w-full border border-slate-300 rounded-lg bg-slate-100 dark:bg-slate-700 dark:border-slate-600 ">
                        <li className="w-full px-4 py-2 border-b-0 lg:border-b rounded-t-lg border-slate-300 dark:border-slate-600">
                            <p className="text-sm font-medium text-blue-500 dark:text-lime-400">Order By</p>
                            <div className="text-xs font-thin mb-6 mt-2 ml-2">
                                <form>
                                    <div className="mb-1 ">
                                        <input
                                            type="radio"
                                            id="recents"
                                            name="options"
                                            value="recents"
                                            checked={orderStatsBy === 'recents'}
                                            onChange={handleOrderByChange}
                                            className="hidden peer"
                                        />
                                        <label htmlFor="recents" className="inline-flex items-center justify-between w-full p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-200 dark:border-gray-700 dark:peer-checked:text-blue-400 peer-checked:border-blue-600 peer-checked:text-blue-500 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-black">
                                            <div className="flex flex-row items-center">
                                                <ArrowTrendingUpIcon className="w-3 h-3 mr-1" />
                                                <span>Recents</span>
                                            </div>
                                            <CheckCircleIcon className="w-3 h-3" />
                                        </label>
                                    </div>

                                    <div className="mb-1">
                                        <input
                                            type="radio"
                                            id="views"
                                            name="options"
                                            value="views"
                                            checked={orderStatsBy === 'views'}
                                            onChange={handleOrderByChange}
                                            className="hidden peer"
                                        />
                                        <label htmlFor="views" className="inline-flex items-center justify-between w-full p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-200 dark:border-gray-700 dark:peer-checked:text-blue-400 peer-checked:border-blue-600 peer-checked:text-blue-500 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-black">
                                            <div className="flex flex-row items-center">
                                                <EyeIcon className="w-3 h-3 mr-1" />
                                                <span>Views</span>
                                            </div>
                                            <CheckCircleIcon className="w-3 h-3" />
                                        </label>
                                    </div>

                                    <div className="mb-1">
                                        <input
                                            type="radio"
                                            id="downloads"
                                            name="options"
                                            value="downloads"
                                            checked={orderStatsBy === 'downloads'}
                                            onChange={handleOrderByChange}
                                            className="hidden peer"
                                        />
                                        <label htmlFor="downloads" className="inline-flex items-center justify-between w-full p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-200 dark:border-gray-700 dark:peer-checked:text-blue-400 peer-checked:border-blue-600 peer-checked:text-blue-500 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-black">
                                            <div className="flex flex-row items-center">
                                                <CloudArrowDownIcon className="w-3 h-3 mr-1" />
                                                <span>Downloads</span>
                                            </div>
                                            <CheckCircleIcon className="w-3 h-3" />
                                        </label>
                                    </div>
                                </form>
                            </div>

                            {/*<div className="flex flex-col rounded-md text-xs font-thin mb-6 mt-2 ml-2 w-fit">
                                <button className="inline-flex items-center px-4 py-2 text-slate-600 bg-transparent border-t border-x rounded-t-lg border-slate-700 hover:bg-slate-500 hover:text-white focus:outline-none focus:bg-slate-700 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-500 dark:focus:bg-slate-500 cursor-pointer">
                                    <ArrowTrendingUpIcon className="w-3 h-3 mr-1" />
                                    <span>Recents</span>
                                </button>
                                <button className="inline-flex items-center px-4 py-2 text-slate-600 bg-transparent border border-slate-700 hover:bg-slate-500 hover:text-white focus:outline-none focus:bg-slate-700 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-500 dark:focus:bg-slate-500">
                                    <EyeIcon className="w-3 h-3 mr-1" />
                                    <span>Views</span>
                                </button>
                                <button className="inline-flex items-center px-4 py-2 text-slate-600 bg-transparent border-b border-x rounded-b-lg border-slate-700 hover:bg-slate-500 hover:text-white focus:outline-none focus:bg-slate-700 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-500 dark:focus:bg-slate-500">
                                    <CloudArrowDownIcon className="w-3 h-3 mr-1" />
                                    <span>Downloads</span>
                                </button>
                            </div>*/}

                        </li>
                        {profileExists &&
                            <li className="w-full px-4 py-2 lg:block hidden rounded-b-lg border-slate-300 dark:border-slate-600">
                                <p className="text-sm font-medium text-blue-500 dark:text-lime-400">Your Stats</p>
                                <div className="flex flex-col rounded-md text-sm font-thin mb-6 mt-2 ml-2 w-fit">
                                    <span className="text-blue-600 dark:text-lime-200">{profile.viewedPosts.length} views</span>
                                    <span className="text-blue-600 dark:text-lime-200">{profile.downloadedPosts.length} downloads</span>
                                    <span className="text-blue-600 dark:text-lime-200">Last read: Luke 2:3-5</span>
                                </div>
                            </li>}
                        {!profileExists &&
                            <li className="w-full px-4 py-2 lg:block hidden rounded-b-lg border-slate-300 dark:border-slate-600">
                                <p className="text-sm font-medium text-gray-400 dark:text-gray-400">Create profile to track your history</p>
                            </li>}
                    </ul>}
                {!enableStatsSorting &&
                    <ul className="w-full border border-slate-300 rounded-lg bg-slate-100 dark:bg-slate-700 dark:border-slate-600 ">
                        {profileExists &&
                            <li className="w-full px-4 py-2 rounded-b-lg border-slate-300 dark:border-slate-600">
                                <p className="text-sm font-medium text-blue-500 dark:text-lime-400">Your Stats</p>
                                <div className="flex flex-col rounded-md text-sm font-thin mb-6 mt-2 ml-2 w-fit">
                                    <span className="text-blue-600 dark:text-lime-200">{profile.viewedPosts.length} views</span>
                                    <span className="text-blue-600 dark:text-lime-200">{profile.downloadedPosts.length} downloads</span>
                                    <span className="text-blue-600 dark:text-lime-200">Last read: Luke 2:3-5</span>
                                </div>
                            </li>}
                        {!profileExists &&
                            <li className="w-full px-4 py-2 rounded-b-lg border-slate-300 dark:border-slate-600">
                                <p className="text-sm font-medium text-gray-400 dark:text-gray-400">Create profile to track your history</p>
                            </li>}
                    </ul>}
            </div>
        </div>
    )
}

export default SideBarRight;