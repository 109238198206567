import { SocialIcon } from "react-social-icons";
import { useThemeContext } from './AppContext';

function Footer() {
    const { isDarkMode } = useThemeContext();

    const iconProps = {
        style: { height: 25, width: 25 },
        target: '_blank',
        rel: 'noreferrer',
    };


    return (
        <footer className="relative bottom-0 md:ml-52 lg:ml-64 sm:mr-48 lg:mr-64 border-t border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800">
            <div className="mx-auto pb-4">
                <div className="flex justify-center pt-4 pb-2">
                    <div className="text-sm">
                        <span>
                            <SocialIcon
                                className="mr-4"
                                network="facebook"
                                fgColor={isDarkMode ? '#000000' : '#ffffff'}
                                bgColor={isDarkMode ? '#ffffff' : '#000000'}
                                url="https://www.facebook.com/pages/Sharemiale/181306448734024"
                                {...iconProps}
                            />
                        </span>

                        <span>
                            <SocialIcon
                                className="mr-4"
                                network="twitter"
                                fgColor={isDarkMode ? '#000000' : '#ffffff'}
                                bgColor={isDarkMode ? '#ffffff' : '#000000'}
                                url="https://twitter.com/share_miale"
                                {...iconProps}
                            />
                        </span>

                        <span>
                            <SocialIcon
                                className="mr-4"
                                network="github"
                                fgColor={isDarkMode ? '#000000' : '#ffffff'}
                                bgColor={isDarkMode ? '#ffffff' : '#000000'}
                                url="https://github.com/owuordickson/sharemiale-2.git"
                                {...iconProps}
                            />
                        </span>
                    </div>
                </div>

                <div className="flex justify-center text-xs text-gray-800 dark:text-slate-400">
                    © 2023 Sharemiale. All Rights Reserved.
                </div>
            </div>
        </footer>
    );
}

export default Footer;